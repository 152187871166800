import React from 'react'
import medida from '../Imagenes/produtos/medidas.jpg'
import transicionBottomTop from '../Transicion/transicionBottomTop'

const Medida = () => {
    return (
        <main className='container-xxl medide-container seccion-grande'>
            <div className="row justify-content-around py-5">
                <div className="imagen col-lg-7 col-11">
                    <img src={medida} alt="medida" />
                </div>
                <div className="contenido col-lg-5 col-11">
                    <div className="px-4">
                        <h1 className='py-5'>Lacres linha especial Sherlock, com Tripla trava</h1>
                        <p className='fs-5 py-3'>Os lacres Sherlock estão disponíveis em diferentes tamanhos, variando de 16 cm a 44 cm, para atender às necessidades específicas de segurança de cada cliente.Seja para aplicações menores, como os de 16 cm, ou para grandes volumes com o lacre de 44 cm</p>
                        <p className='fs-5 py-3'></p>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default transicionBottomTop(Medida)