import './Componentes/App.scss'
import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import Footer from './Componentes/Footer/Footer';
import Navbar from './Componentes/Navbar/Navbar';

import transicionBottomTop from './Componentes/Transicion/transicionBottomTop'
import AnimatedRoutes from './AnimatedRoutes';

function App() {
  return (
    <>
        <BrowserRouter>
          <Navbar />
          <AnimatedRoutes />
          <Footer />
        </BrowserRouter>
    </>
  );
}

export default transicionBottomTop(App);
