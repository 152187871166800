import React, { useEffect, useRef, useState } from 'react'
import sherlocktr4 from '../Imagenes/produtos/sherlock-tr4.webp'
import lacrestripletrava from '../Imagenes/produtos/lacres-tripla-trava.webp'
import duplaanchora from '../Imagenes/produtos/dupla-anchora.webp'
import lacrebreakaway from '../Imagenes/produtos/lacre-breakaway.webp'
import oneseal from '../Imagenes/produtos/one-seal.webp'
import pb25 from '../Imagenes/produtos/pb-25.webp'
import securlock1 from '../Imagenes/produtos/securlock1.webp'
import securlock2 from '../Imagenes/produtos/securlock2.webp'
import sherlock3 from '../Imagenes/produtos/sherlock-3.webp'
import sherlock44_1 from '../Imagenes/produtos/sherlock44-1.webp'
import sherlock44_2 from '../Imagenes/produtos/sherlock44-2.webp'
import sherlock44_3 from '../Imagenes/produtos/sherlock44-3.webp'
import sherlock45_1 from '../Imagenes/produtos/sherlock45-1.webp'
import sherlock45_2 from '../Imagenes/produtos/sherlock45-2.webp'
import sherlock47 from '../Imagenes/produtos/sherlock47.webp'
import cadeadoblindado from '../Imagenes/produtos/cadeado-blindado.webp'
import Colores from './Colores'
import { useParams } from 'react-router-dom'
import transicionBottomTop from '../Transicion/transicionBottomTop'

const ProdutoDetail = () => {
    const isMountedRef = useRef(false);
    const produtos = [
        {
            imagenes: [sherlocktr4],
            titulo: "sherlock-tr4",
            parrafouno: "MATERIAL: POLICARBONATO.",
            parrafodos: "Triplica a resistência ás temperaturas, não pode ser aberto na água quente, (a violação mais comum nos obsoletos lacres de âncora em polipropileno, ainda utilizados..).RESISTÊNCIA A TRAÇÃO: 50 Kgf no dinamômetro. (triplica também essa função).GRAVAÇÕES : com código de barras e QR (precisão absoluta na transmissão das informações)",
            colores: ["red","green","blue","yellow"]
        },{
            imagenes: [lacrestripletrava],
            titulo: "lacres-triple-trava",
            parrafouno: "Lacres de engenharia avançada, com tripla trava interna, que impede qualquer violação do selo por manipulação ou força aplicada no mesmo.",
            parrafodos: "Personalizado: Com código de barras ou QR gravado á laser, no corpo do lacre ou etiqueta aplicada.Principais usos Malotes, caminhões (baú, lonado e outros), caminhões-tanque (para combustíveis, água e produtos químicos), tambores, bombonas, balanças, produtos químicos, almoxarifados, armários, computadores (CPU), registros, válvulas, contentores, containers, big bags, malas de viagem, instrumentos em geral, extintores de incêndio, etc.",
            colores: ["red","green","blue","yellow"]
        },{
            imagenes: [duplaanchora],
            titulo: "dupla-anchora",
            parrafouno: "MATERIAL: POLICARBONATO.",
            parrafodos: "Triplica a resistência ás temperaturas, não pode ser aberto na água quente, (a violação mais comum nos obsoletos lacres de âncora em polipropileno, ainda utilizados..).RESISTÊNCIA A TRAÇÃO: 50 Kgf no dinamômetro. (triplica também essa função).GRAVAÇÕES : com código de barras e QR (precisão absoluta na transmissão das informações).",
            colores: ["red","green","blue","yellow"]
        },{
            imagenes: [lacrebreakaway],
            titulo: "lacre-breakaway",
            parrafouno: "Lacre metálico de cabo-de-aço constituído por um corpo de trava feito de liga zamac, uma alavanca de trava e cabo-de-aço galvanizado, diâmetro: 1,71 mm. A selagem/lacração é muito rápida. O Sherlock Cable Seal Breakaway possui 2 excelentes áreas para personalização e numeração. Fornecido em cores diversas e na liga natural zamac. Pode também ser fornecido em versão com fio de nylon, diâmetro: 1,60 mm.",
            parrafodos: "",
            colores: ["red","green","blue","yellow"]
        },{
            imagenes: [oneseal],
            titulo: "one-seal",
            parrafouno: "",
            parrafodos: "",
            colores: ["red","green","blue","yellow"]
        },{
            imagenes: [pb25],
            titulo: "pb-25",
            parrafouno: "",
            parrafodos: "",
            colores: ["red","green","blue","yellow"]
        },{
            imagenes: [securlock1],
            titulo: "securlock-I",
            parrafouno: "",
            parrafodos: "",
            colores: ["red","green","blue","yellow"]
        },{
            imagenes: [securlock2],
            titulo: "securlock-II",
            parrafouno: "",
            parrafodos: "",
            colores: ["red","green","blue","yellow"]
        },{
            imagenes: [sherlock3],
            titulo: "sherlock-III",
            parrafouno: "",
            parrafodos: "",
            colores: ["red","green","blue","yellow"]
        },{
            imagenes: [sherlock44_1,sherlock44_2,sherlock44_3],
            titulo: "sherlock-44",
            parrafouno: "Lacre com 44 cm de comprimento total, de alta resistência (35 Kgf) e fácil manuseio (pre-corte).Especial para as indústrias de açúcar e fertilizantes. Com logotipo e numeração seqüencial de 7 dígitos gravados por hot-stamping ou laser. Com opção de etiquetas com código de barras repetindo logo e numeração gravados no selo. Embalagem: caixas com 1000 unidades.",
            parrafodos: "",
            colores: ["red","green","blue","yellow"]
        },{
            imagenes: [sherlock45_1,sherlock45_2],
            titulo: "sherlock-45",
            parrafouno: "Lacre com duplo fechamento interno sob pressão dentro da cápsula, ficando completamente isolado e tornando qualquer tentativa de violação ineficaz.Comprimento total: 46,5 CMForça de Ruptura: >27 KGF. UTILIDADE: Big bags, Fechamento de Sacas, Transportes de cargas em geral, fechamento de lonas, Mangueira de hidrante, e outras necessidades HEAVY DUTY. GRAVAÇÕES: Logotipo, numeração sequencial ou códigos de barras. ",
            parrafodos: "",
            colores: ["red","green","blue","yellow"]
        },{
            imagenes: [sherlock47],
            titulo: "sherlock-47",
            parrafouno: "",
            parrafodos: "",
            colores: ["red","green","blue","yellow"]
        },{
            imagenes: [cadeadoblindado],
            titulo: "cadeado-blindado",
            parrafouno: "Com estrutura externa em aleação ZAMAC (protegido contra corrosão) e mecanismo interno metálico, suportando 120 Kgf (Norma Nacional)",
            parrafodos: "Cabo ajustável(no comprimento da sua necessidade)De fácil travamento, com 2 travas internas security ball, 100% inviolável, dispensa o uso de ferramentas para lacrar a peça..",
            colores: ["red","green","blue","yellow"]
        }
    ]
    const [indexGaleria,setIndexGaleria] = useState(0)
    const {produtoURL} = useParams()
    const indexProduto = produtos.findIndex((produto) => produto.titulo === produtoURL)
    let produto = produtos[indexProduto]
    const [imagenGaleria,setImagenGaleria] = useState(produto.imagenes[0])    
    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    }, []);
    useEffect(()=>{
        produto = produtos[indexProduto]
        setImagenGaleria(produto.imagenes[0])
    },[produtoURL])

    return (
        <main className='produto-detail-container'>
            <div className="container-xxl seccion-grande">
                <div className="row justify-content-between align-items-center py-5 px-md-5 px-1">
                    <div className="col-11 col-md-5 produto-imagen position-relative">
                        <img src={imagenGaleria} alt={produto.titulo} />
                        {produto.imagenes.length > 1 ? (
                            <div className="flechas w-100 d-flex justify-content-between">
                                <svg
                                id='flecha-izquierda'
                                width="50"
                                height="50"
                                viewBox="0 0 65 64"
                                strokeWidth="1.5"
                                stroke="#000000"
                                fill="none"
                                onClick={() => {
                                    if (indexGaleria !== 0) {
                                    setIndexGaleria((prevIndex) => prevIndex - 1);
                                    setImagenGaleria(produto.imagenes[indexGaleria - 1]);
                                    }
                                }}
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M17 6h-6a1 1 0 0 0 -.78 .375l-4 5a1 1 0 0 0 0 1.25l4 5a1 1 0 0 0 .78 .375h6l.112 -.006a1 1 0 0 0 .669 -1.619l-3.501 -4.375l3.5 -4.375a1 1 0 0 0 -.78 -1.625z" stroke-width="0" fill="currentColor" />
                                </svg>
                                <svg
                                id='flecha-derecha'
                                width="50"
                                height="50"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="#000000"
                                fill="none"
                                onClick={() => {
                                    if (indexGaleria !== produto.imagenes.length - 1) {
                                    setIndexGaleria((prevIndex) => prevIndex + 1);
                                    setImagenGaleria(produto.imagenes[indexGaleria + 1]);
                                    }
                                }}
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M7 6l-.112 .006a1 1 0 0 0 -.669 1.619l3.501 4.375l-3.5 4.375a1 1 0 0 0 .78 1.625h6a1 1 0 0 0 .78 -.375l4 -5a1 1 0 0 0 0 -1.25l-4 -5a1 1 0 0 0 -.78 -.375h-6z" stroke-width="0" fill="currentColor" />
                                </svg>
                            </div>
                        ) : null}
                    </div>
                    <div className="col-11 col-md-6 produto-descripcion px-4">
                        <h2 className='poppins fw-semibold pt-5 pb-4 mb-5 fs-1'>{produto.titulo}</h2>
                        <p className='poppins fw-regular fs-5'>{produto.parrafouno}</p>
                        <p className='poppins fw-regular fs-5'>{produto.parrafodos}</p>
                        <Colores colores={produto.colores} />
                    </div>
                </div>
            </div>
        </main>
    )
}

export default transicionBottomTop(ProdutoDetail)