import React from 'react'
import whatsapp from '../Imagenes/whatsapp.png'

const WhatsappIcon = () => {
    const linkWsp = 'https://api.whatsapp.com/send?phone=5511912300361&text=Agradecemos%20o%20seu%20contato,%20como%20podemos%20ajudar?';

    return (
        <a href={linkWsp} target='_blank'><img className='whatsappIcon' src={whatsapp} alt="whatsapp chat" /></a>
    )
}

export default WhatsappIcon