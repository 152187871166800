import React from 'react'
import Home from './Componentes/Home/Home';
import Empresa from './Componentes/Empresa/Empresa'
import Produtos from './Componentes/Produtos/Produtos'
import Dicas from './Componentes/Dicas/Dicas'
import Contato from './Componentes/Contato/Contato'
import WhatsappIcon from './Componentes/Flotantes/WhatsappIcon';
import Consultor from './Componentes/Flotantes/Consultor';
import ProdutoDetail from './Componentes/Produtos/ProdutoDetail';
import Medida from './Componentes/Produtos/Medida';
import Utilidade from './Componentes/Produtos/Utilidade';
import { Routes,Route,useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion'

const AnimatedRoutes = () => {
    const location = useLocation()

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={<Home />} />
                <Route path='/Empresa/' element={<Empresa />} />
                <Route path='/Produtos/' element={<Produtos />} />
                <Route path='/Dicas/' element={<Dicas />} />
                <Route path='/Contato/' element={<Contato />} />
                <Route path='/Produtos/medida' element={<Medida />} />
                <Route path='/Produtos/utilidade' element={<Utilidade />} />
                <Route path='/Produtos/:produtoURL' element={<ProdutoDetail />} />
            </Routes>
            <div className="flotantes d-flex flex-column gap-3">
                <WhatsappIcon />
                <Consultor />
            </div>
        </AnimatePresence>
    )
}

export default AnimatedRoutes