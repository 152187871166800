import React from 'react'
import ImageGallery from "react-image-gallery";
import { useRef } from 'react';

const GaleriaProdutos = ({produtos}) => {
    const isPlaying = useRef(true);
    const renderCustomControls = () => {
        if(isPlaying.current!=true){
            const currentIndex = isPlaying.current.getCurrentIndex();
            const currentImagePath = produtos[currentIndex].path;
            const currentTitulo = produtos[currentIndex].titulo;
            return (
                <div className="custom-controls-container d-flex justify-content-center">
                    <a href={currentImagePath} className="image-gallery-custom-action overlay z-5"></a>
                    <div className='d-flex justify-content-center position-absolute bottom-0 py-5 px-4 '>
                        <p className='z-5 fs-5 text-center text-shadow-bold'>{currentTitulo}</p>
                    </div>
                </div>
            );
        } 
    };

    return (
        <ImageGallery 
            ref={isPlaying}
            items={produtos}
            showPlayButton={false}
            autoPlay={true}
            showFullscreenButton={false}
            showBullets={true}
            lazyLoad={true}
            showThumbnails={false}

            onMouseOver={()=>{
                isPlaying.current.pause()
            }}
            onMouseLeave={()=>{
                isPlaying.current.play()
            }}
            renderCustomControls={renderCustomControls}
            />
    )
}

export default GaleriaProdutos