import React, { useState, useEffect } from 'react';
import video from '../Videos/barras.mp4';
import logo from '../Imagenes/logo-sherlock.webp';

const GaleriaIntro = ({ introSecciones }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    // Cambiar automáticamente el slide cada 6 segundos
    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % introSecciones.length);
        }, 6000);

        return () => clearInterval(interval); // Limpiar el intervalo al desmontar el componente
    }, [introSecciones.length]);

    return (
        <>
            <div className="galeria-intro d-flex flex-column align-items-center">
                <div className="slide h-100">
                    {introSecciones[activeIndex].original === video ? (
                        <video muted autoPlay loop playsInline>
                            <source src={introSecciones[activeIndex].original} type="video/mp4" />
                            Tu navegador no soporta la reproducción de videos.
                        </video>
                    ) : (
                        <div className="position-relative h-100">
                            <img src={introSecciones[activeIndex].original} alt="" />
                            <div className="img-overlay">
                                <div className="content p-5">
                                    <h2 className="poppins titulo-mediano fw-bold">{introSecciones[activeIndex].subtitulo}</h2>
                                    <h1 className="poppins titulo-grande fw-bold">{introSecciones[activeIndex].titulo}</h1>
                                    <p className='py-1 fs-5 text-light'>{introSecciones[activeIndex].parrafoUno}</p>
                                    <p className='py-1 fs-5 text-light'>{introSecciones[activeIndex].parrafoDos}</p>
                                    <div className="d-inline-flex gap-3">
                                        <a href={introSecciones[activeIndex].path}>{introSecciones[activeIndex].textoBoton}</a>
                                        {introSecciones[activeIndex].prontaEntrega ? (<p className='pronta-entrega'>PRONTA ENTREGA</p>) : (null)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="bullets d-flex">
                    {introSecciones.map((_, index) => (
                        <button
                            key={index}
                            className={`bullet ${index === activeIndex ? 'active' : ''}`}
                            onClick={() => setActiveIndex(index)}
                        />
                    ))}
                </div>
            </div>
            <div className="img-description-below">
                {introSecciones[activeIndex].descripcion ? (
                    <div className="content p-5">
                        <h2 className="poppins titulo-mediano fw-bold">{introSecciones[activeIndex].subtitulo}</h2>
                        <h1 className="poppins titulo-grande fw-bold">{introSecciones[activeIndex].titulo}</h1>
                        <p className='py-1 fs-5 text-light'>{introSecciones[activeIndex].parrafoUno}</p>
                        <p className='py-1 fs-5 text-light'>{introSecciones[activeIndex].parrafoDos}</p>
                        <a href={introSecciones[activeIndex].path}>{introSecciones[activeIndex].textoBoton}</a>
                        {introSecciones[activeIndex].prontaEntrega ? (<p className='pronta-entrega'>PRONTA ENTREGA</p>) : (null)}
                    </div>
                ) : (
                    <div className="content p-5 d-flex w-100 justify-content-center align-items-center">
                        <img className='rounded-5' src={logo} />
                    </div>
                )}
            </div>
        </>
    );
};

export default GaleriaIntro;
