import React from 'react'
import Logo from '../Imagenes/logo-sherlock.webp'

const Consultor = () => {
    return (
        <div className='consultaIcon d-flex gap-2'>
            <div>
                <img src={Logo} alt="Logo" />
            </div>
            <p>Fale com o seu consultor</p>
        </div>
    )
}

export default Consultor