import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer className='container-xxl footer'>
            <div className="row">
                <div className="col py-5 my-5">
                    <p>Sherlock Produtos de Segurança Ltda.</p>
                    <p className='d-flex gap-3 align-items-center'>
                    <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                        <path d="M3 7l9 6l9 -6" />
                    </svg>
                        <a href="https://mail.google.com/mail/?view=cm&to=comercial@sherlock.com.br" target='_blank'>comercial@sherlock.com.br</a>
                    </p>
                    <p className='d-flex gap-3 align-items-center fw-medium'>
                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                            <path d="M10 15v-6h2a2 2 0 1 1 0 4h-2" />
                            <path d="M14 15l-2 -2" />
                        </svg>
                        Todos os direitos reservados.
                    </p>
                </div>
                <div className="col py-5 my-5">
                    <ul className='d-flex flex-column gap-4'>
                        <li className='list-unstyled'><Link to="/Empresa">Empresa</Link></li>
                        <li className='list-unstyled'><Link to="/Produtos">Produtos</Link></li>
                        <li className='list-unstyled'><Link to="/Dicas">Dicas de seguranca</Link></li>
                    </ul>
                </div>
                <div className="col py-5 my-5">
                    <p>Si Se deseja nos fazer uma consulta, não hesite em nos contactar!</p>
                    <button><Link to="/Contato">CONTATO</Link></button>
                </div>
            </div>
        </footer>
    )
}

export default Footer