import React from 'react'
import { Link } from 'react-router-dom'
import transicionBottomTop from '../Transicion/transicionBottomTop'

const Utilidade = () => {
    return (
        <main className='utilidade-container'>
            <div className="container-xxl py-5">
                <table className="table table-bordered">
                    <thead className="thead-light">
                    <tr>
                        <th>Modelos Sherlock</th>
                        <th><Link to="/Produtos/sherlock-44">Sherlock 44 cm</Link></th>
                        <th><Link to="">Sherlock 40 cm TT</Link></th>
                        <th><Link to="/Produtos/sherlock-45">Sherlock 45 cm</Link></th>
                        <th><Link to="/Produtos/lacres-triple-trava">Sherlock 35 cm</Link></th>
                        <th><Link to="/Produtos/sherlock-tr4">Sherlock TR 4</Link></th>
                        <th><Link to="/Produtos/lacres-triple-trava">Sherlock 28 cm</Link></th>
                        <th><Link to="/Produtos/lacres-triple-trava">Sherlock 23 cm</Link></th>
                        <th><Link to="/Produtos/lacres-triple-trava">Sherlock 16 cm</Link></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="font-weight-bold">Características</td>
                        <td>Força 36 kg</td>
                        <td>Tripla Trava</td>
                        <td>Força 28 kg</td>
                        <td>Tripla Trava</td>
                        <td>Cadeado c. arame</td>
                        <td>Tripla Trava</td>
                        <td>Tripla Trava</td>
                        <td>Tripla Trava</td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold">Big Bags</td>
                        <td><Link to="/Produtos/sherlock-44" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td><Link to="" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td><Link to="/Produtos/sherlock-45" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold">Lonas de caminhão</td>
                        <td><Link to="/Produtos/sherlock-44" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/sherlock-45" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/sherlock-tr4" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold">Caminhão tanque</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><Link to="/Produtos/sherlock-tr4" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-success">ATENDE</Link></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold">Válvulas de combustível</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><Link to="/Produtos/sherlock-tr4" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-success">ATENDE</Link></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold">Produtos químicos</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><Link to="/Produtos/sherlock-tr4" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold">Caixas lacradas</td>
                        <td></td>
                        <td><Link to="" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-success">ATENDE</Link></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold">Caixotes logística</td>
                        <td></td>
                        <td><Link to="" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td><Link to="/Produtos/sherlock-45" className="btn btn-success">ATENDE</Link></td>
                        <td></td>
                        <td></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-success">ATENDE</Link></td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold">Sacarias</td>
                        <td><Link to="/Produtos/sherlock-44" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td><Link to="" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/sherlock-45" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-success">ATENDE</Link></td>
                        <td></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-success">ATENDE</Link></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold">Supermercados, mercadoria em trânsito</td>
                        <td><Link to="/Produtos/sherlock-44" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td><Link to="" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td><Link to="/Produtos/sherlock-45" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-success">ATENDE</Link></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold">Containers</td>
                        <td><Link to="/Produtos/sherlock-44" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="" className="btn btn-success">ATENDE</Link></td>
                        <td></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/sherlock-tr4" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-success">ATENDE</Link></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold">Baús, portas e portões</td>
                        <td><Link to="/Produtos/sherlock-44" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/sherlock-45" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/sherlock-tr4" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-success">ATENDE</Link></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold">Almoxarifados</td>
                        <td><Link to="/Produtos/sherlock-44" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td><Link to="" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td><Link to="/Produtos/sherlock-45" className="btn btn-success">ATENDE</Link></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-success">ATENDE</Link></td>
                        <td></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-success">ATENDE</Link></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold">Malotes</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><Link to="/Produtos/lacres-triple-trava" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td><Link to="/Produtos/sherlock-tr4" className="btn btn-primary">RECOMENDADO</Link></td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold">Hidrômetros e medidores</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><Link to="/Produtos/sherlock-tr4" className="btn btn-primary">RECOMENDADO</Link></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </main>
    )
}

export default transicionBottomTop(Utilidade)