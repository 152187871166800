import React from 'react'
import fabrica from '../Imagenes/fabrica.webp'
import { useRef,useEffect } from 'react';
import transicionBottomTop from '../Transicion/transicionBottomTop';

const Empresa = () => {
    const isMountedRef = useRef(false);
    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    }, []);

    return (
        <main className='empresa-container seccion-grande'>
            <div className="container-xxl d-flex">
                <div className="row mt-5 justify-content-center">
                    <div className="intro col-md-11 col-lg-7 rounded-4">
                        <div className='px-sm-1 px-md-5'>
                            <h1 className='poppins fw-bold fs-2 pt-5'>NOSSA EMPRESA</h1>
                            <h2 className='poppins fs-3 pt-4 pb-5 d-flex gap-3'>
                                Sherlock Produtos de Segurança Ltda 
                                <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M11.5 21h-4.5a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v.5" />
                                    <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" />
                                    <path d="M8 11v-4a4 4 0 1 1 8 0v4" />
                                    <path d="M15 19l2 2l4 -4" />
                                </svg>
                            </h2>
                            <p className='fs-5 fw-medium pt-4'>Recolhendo 20 anos de experiência dos seus antecessores, atua no segmento de lacres e selos de segurança, tendo logrado um merecido prestígio a nível internacional pela qualidade e segurança os seus produtos.</p>
                            <p className='fs-5 fw-medium pt-4'>A nossa meta é oferecer produtos cada vez mais seguros, econômicos e eficientes, atendendo as normas sociais e ambientais do mercado moderno.</p>
                        </div>
                    </div>
                    <div className="col-md-11 col-lg-5 rounded-4">
                        <img src={fabrica} className='w-100 h-100 object-fit-cover rounded-4' alt="fabrica" />
                    </div>
                </div>
            </div>
            <div className="my-5 py-5">
                <div className="container-xxl d-flex flex-wrap gap-3 justify-content-center">
                    <div className="col-sm-4 col-12 bg-amarillo-4 py-5 rounded-4">
                        <h2 className='d-flex flex-column align-items-center gap-4 fw-semibold poppins fs-3 px-3 text-center'>
                            <svg width="60" height="60" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M11.46 20.846a12 12 0 0 1 -7.96 -14.846a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3a12 12 0 0 1 -.09 7.06" />
                                <path d="M15 19l2 2l4 -4" />
                            </svg>
                            Alta qualidade
                        </h2>
                    </div>
                    <div className="col-sm-7 col-12 bg-amarillo-1 py-5 rounded-4">
                        <h2 className='d-flex flex-column align-items-center gap-4 fw-semibold poppins fs-3 px-3 text-center'>
                            <svg width="60" height="60" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M11.5 21h-5.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v6" />
                                <path d="M16 3v4" />
                                <path d="M8 3v4" />
                                <path d="M4 11h16" />
                                <path d="M15 19l2 2l4 -4" />
                            </svg>
                            +20 anos de experiência
                        </h2>
                    </div>
                    <div className="col-sm-7 col-12 bg-amarillo-5 py-5 rounded-4 d-flex flex-column align-items-center gap-2 fw-semibold">
                        <h2 className='d-flex flex-column align-items-center gap-4 fw-semibold poppins fs-3'>
                            <svg width="60" height="60" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M17.1 8.648a.568 .568 0 0 1 -.761 .011a5.682 5.682 0 0 0 -3.659 -1.34c-1.102 0 -2.205 .363 -2.205 1.374c0 1.023 1.182 1.364 2.546 1.875c2.386 .796 4.363 1.796 4.363 4.137c0 2.545 -1.977 4.295 -5.204 4.488l-.295 1.364a.557 .557 0 0 1 -.546 .443h-2.034l-.102 -.011a.568 .568 0 0 1 -.432 -.67l.318 -1.444a7.432 7.432 0 0 1 -3.273 -1.784v-.011a.545 .545 0 0 1 0 -.773l1.137 -1.102c.214 -.2 .547 -.2 .761 0a5.495 5.495 0 0 0 3.852 1.5c1.478 0 2.466 -.625 2.466 -1.614c0 -.989 -1 -1.25 -2.886 -1.954c-2 -.716 -3.898 -1.728 -3.898 -4.091c0 -2.75 2.284 -4.091 4.989 -4.216l.284 -1.398a.545 .545 0 0 1 .545 -.432h2.023l.114 .012a.544 .544 0 0 1 .42 .647l-.307 1.557a8.528 8.528 0 0 1 2.818 1.58l.023 .022c.216 .228 .216 .569 0 .773l-1.057 1.057z" />
                            </svg>
                            Econômico
                        </h2>
                        <p className='fw-semibold poppins fs-4 px-3 text-center'>Melhor relação preço do mercado</p>
                    </div>
                    <div className="col-sm-4 col-12 bg-negro py-5 rounded-4">
                        <h2 className='d-flex flex-column align-items-center gap-4 fw-semibold poppins fs-3 text-light'>
                            <svg width="60" height="60" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                                <path d="M8 11m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z" />
                                <path d="M10 11v-2a2 2 0 1 1 4 0v2" />
                            </svg>
                            <p className='text-center mt-3 px-3'>Confiável e seguro</p>
                        </h2>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default transicionBottomTop(Empresa)